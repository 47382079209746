.modal {
    background: rgba(0, 0, 0, 0.3);

    &.fade {
        .modal-dialog {
            opacity: 0;
            transform: scale(0.9);
            transition: 100ms ease-in-out;
        }

        &.in .modal-dialog {
            transform: scale(1);
            opacity: 1;
        }
    }

    .modal-header {
        padding: 15px;
        border-bottom-color: #e9e9e9;

        > .close {
            font-size: 18px;
            outline: 0;
            margin-top: 2px;
            -webkit-text-stroke: 1px #ffffff;
            transition: 200ms ease-in-out;
        }
    }

    .modal-content {
        border: none;
        border-radius: 3px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
    }

    .modal-footer {
        border-radius: 0 0 3px 3px;
        border-top-color: #e9e9e9;

        .btn + .btn {
            margin-left: 12px;
        }
    }
}

#confirmation-modal {
    .modal-header {
        padding: 15px 15px 15px 25px;
    }

    .modal-body {
        padding: 0px 15px 25px 25px;
    }

    .modal-header,
    .modal-footer {
        border: none;
    }

    .default-message {
        color: #737881;
    }

    .modal-footer {
        background: #f1f3f7;
    }

    .btn-default {
        background: #e1e1e1;

        &:hover {
            background: #e9e9e9;
        }
    }
}
