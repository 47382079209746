@import '~bootstrap/dist/css/bootstrap';
@import '~font-awesome/css/font-awesome';
@import '~nprogress/nprogress';
@import '~datatables.net-bs/css/dataTables.bootstrap';
@import '~flatpickr/dist/ie';
@import './datatables';
@import './selectize';
@import './wysiwyg';
@import './ohsnap';
@import './flatpickr';
@import './classes';
@import './tab';
@import './alert';
@import './modal';
@import './utilities';
@import './accordion';
@import './fleetcart';
@import './panel';

html {
    direction: ltr;
}

.btn-actions {
    margin: 0 15px 15px 0;

    > i {
        margin-right: 5px;
   }
}

.overflow-hidden {
	overflow: hidden;
}

#nprogress {
    .bar {
        background: #0068e1;
    }

    .peg {
        box-shadow: 0 0 10px #0068e1, 0 0 5px #0068e1;
    }

    .spinner-icon {
        border-top-color: #0068e1;
        border-left-color: #0068e1;
    }
}

.sortable-ghost {
	opacity: .2;
}

.btn-group.open .dropdown-toggle,
.btn-group .dropdown-toggle:active {
	-webkit-box-shadow: none;
    		box-shadow: none;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.green {
    background-color: #37bc9b;
}

.red {
    background-color: #fc4b4b;
}

.options {
    tr td:first-child {
        width: 34px;
        min-width: 34px;
    }

    tr td:last-child {
        width: 60px;
    }

    .drag-icon {
        font-size: 16px;
        color: #737881;
        cursor: move;
        vertical-align: top;
        margin-top: 12px;
        white-space: nowrap;
        display: inline-block;

        i {
            float: left;

            &:nth-child(2) {
                margin-left: 1px;
            }
        }
    }

    .choose-file,
    .delete-row {
        padding: 10px 15px;
    }
}

.pagination {
    margin-bottom: 10px;

    > li {
        float: left;
        margin-left: -1px;
        padding: 0;
        border: 1px solid #e9e9e9;
        transition: 200ms ease-in-out;

        &:hover {
            cursor: pointer;
            background: #f1f1f1;
        }

        &:first-child {
            margin-left: 0;
            border-radius: 3px 0 0 3px;

            a {
                border-radius: 3px 0 0 3px;
            }
        }

        &:last-child {
            border-radius: 0 3px 3px 0;

            a {
                border-radius: 0 3px 3px 0;
            }
        }

        &.disabled {
            cursor: not-allowed !important;

            &:hover {
                background: transparent;
            }
        }

        > a {
            padding: 10px 15px;
            color: #0068e1;
            border: none;
            display: block;
            background: transparent !important;
            text-decoration: none;

            &:hover {
                background: transparent;
            }

            &:hover, &:focus {
                color: #0068e1;
            }
        }

        > span {
            display: block;
            padding: 10px 15px;
            border: none;
        }
    }

    > .active {
        background: #0068e1;
        cursor: default !important;
        border-color: #0068e1;

        &:hover {
            background: #0068e1;
        }

        > a {
            background: transparent !important;
        }

        > span {
            background: transparent !important;
        }
    }
}

.empty {
    color: #626060;
}
