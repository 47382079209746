.panel-wrap {
    .panel {
        margin-bottom: 15px;
        box-shadow:  none;
        border: 1px solid #e9e9e9;
        border-radius: 3px;

        .panel-header {
            padding: 15px;
            background: #f6f6f7;
            border-bottom: 1px solid #e9e9e9;

            .drag-icon {
                font-size: 16px;
                display: inline-block;
                margin: 2px 10px 0 0;
                color: #737881;
                cursor: move;
                white-space: nowrap;

                > i {
                    float: left;

                     &:last-child {
                        margin-left: 1px;
                    }
                }
            }

            .btn {
                padding: 0;
                background: transparent;

                i {
                    -webkit-text-stroke: 1px #f6f6f7;
                }
            }
        }

        .panel-body {
            position: relative;
            padding: 15px;
        }
        
        .panel-image {
            position: absolute;
            left: 15px;
            top: 25px;
            display: flex;
            height: 110px;
            width: 110px;
            justify-content: center;
            align-items: center;
            border: 1px solid #d2d6de;
            border-radius: 3px;
            cursor: pointer;

            > img {
                max-height: 100%;
                max-width: 100%;
            }

            > i {
                position: absolute;
                left: 50%;
                top: 50%;
                font-size: 48px;
                color: #d9d9d9;
                transform: translate(-50%, -50%);
            }
        }

        .panel-content {
            margin-left: 130px;
            padding: 10px 0;

            .checkbox {
                padding-top: 0;
            }
        }

        .form-group {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .panel-wrap {
        .panel {
            .panel-image {
                position: relative;
                left: auto;
                top: auto;
                margin: 15px auto 30px;
            }

            .panel-content {
                margin-left: 0;
            }
        }
    }
}