/* buttons */

.btn {
    font-size: 15px;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    transition: 200ms ease-in-out;
}

.btn-default {
    background: #f1f1f1;
    outline: 0;
    border-color: #dddddd;
    &.focus,
    &:focus,
    &.active {
        background: #f1f1f1;
        outline: 0;
        border-color: #dddddd;
    }

    &:active {
        background: #f1f1f1;
        outline: 0;
        border-color: #dddddd;

        &:hover {
            background: #f1f1f1;
            outline: 0;
            border-color: #dddddd;
        }
    }

    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background: #f1f1f1;
        outline: 0;
        border-color: #dddddd;
    }

    &.disabled {
        &:hover,
        &.focus,
        &:focus {
            background: #f1f1f1;
            outline: 0;
            border-color: #dddddd;
        }
    }

    &[disabled] {
        &:focus,
        &:hover,
        &.focus,
        &.active,
        &:active {
            background: #f1f1f1;
            outline: 0;
            border-color: #dddddd;
        }
    }
}

fieldset[disabled] .btn-default {
    &:focus,
    &.focus,
    &:hover {
        background: #f1f1f1;
        outline: 0;
        border-color: #dddddd;
    }
}

.open > .dropdown-toggle.btn-default {
    background: #f1f1f1;
    outline: 0;
    border-color: #dddddd;

    &:focus,
    &.focus,
    &:hover {
        background: #f1f1f1;
        outline: 0;
        border-color: #dddddd;
    }
}

.btn-default:hover {
    border-color: #dddddd;
    background: #e7e7e7;
}

.btn-primary {
    background: #0068e1;
    outline: 0;

    &.focus,
    &:focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background: #0068e1;
        outline: 0;
    }

    &.disabled {
        &:hover,
        &.focus,
        &:focus {
            background: #0068e1;
            outline: 0;
        }
    }

    &[disabled] {
        &:focus,
        &:hover,
        &.focus,
        &.active,
        &:active {
            background: #0068e1;
            outline: 0;
        }
    }
}

fieldset[disabled] .btn-primary {
    &:focus,
    &.focus,
    &:hover {
        background: #0068e1;
        outline: 0;
    }
}

.open > .dropdown-toggle.btn-primary {
    background: #0068e1;
    outline: 0;

    &:focus,
    &.focus,
    &:hover {
        background: #0068e1;
        outline: 0;
    }
}

.btn-primary:hover {
    background: #0059bd;
}

.btn-danger {
    background: #fc4b4b;

    &.focus,
    &:focus,
    &.active {
        background: #fc4b4b;
    }

    &:active {
        background: #fc4b4b;

        &:hover {
            background: #fc4b4b;
        }
    }

    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background: #fc4b4b;
    }

    &.disabled {
        &:hover,
        &.focus,
        &:focus {
            background: #fc4b4b;
        }
    }

    &[disabled] {
        &:focus,
        &:hover,
        &.focus,
        &.active,
        &:active {
            background: #fc4b4b;
        }
    }
}

fieldset[disabled] .btn-danger {
    &:focus,
    &.focus,
    &:hover {
        background: #fc4b4b;
    }
}

.open > .dropdown-toggle.btn-danger {
    background: #fc4b4b;
    &:focus,
    &.focus,
    &:hover {
        background: #fc4b4b;
    }
}

.btn-danger:hover {
    background: #ff7070;
}

.btn-info {
    background: #4bcffc;

    &.focus,
    &:focus,
    &.active {
        background: #4bcffc;
    }

    &:active {
        background: #4bcffc;

        &:hover {
            background: #4bcffc;
        }
    }

    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background: #4bcffc;
    }

    &.disabled {
        &:hover,
        &.focus,
        &:focus {
            background: #4bcffc;
        }
    }

    &[disabled] {
        &:focus,
        &:hover,
        &.focus,
        &.active,
        &:active {
            background: #4bcffc;
        }
    }
}

fieldset[disabled] .btn-info {
    &:focus,
    &.focus,
    &:hover {
        background: #4bcffc;
    }
}

.open > .dropdown-toggle.btn-info {
    background: #4bcffc;

    &:focus,
    &.focus,
    &:hover {
        background: #4bcffc;
    }
}

.btn-info:hover {
    background: #6fcffd;
}

.btn {
    &:focus,
    &:hover:focus,
    &:active:focus {
        outline: 0;
        box-shadow: none;
    }
}

.btn-loading {
    position: relative;
    color: transparent !important;

    &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 16px;
        width: 16px;
        border: 2px solid #ffffff;
        border-radius: 100%;
        border-right-color: transparent;
        border-top-color: transparent;
        animation: spinAround 600ms infinite linear;
    }

    &.btn-default:after {
        border: 2px solid #0068e1;
        border-right-color: transparent;
        border-top-color: transparent;
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

/* bg color */

.bg-black {
    background: #494f5a;
}

.bg-red {
    background: #fc4b4b;
}

.bg-green {
    background: #37bc9b;
}

.bg-blue {
    background: #0068e1;
}

/* text */

.text-black {
    color: #494f5a;
}

.text-red {
    color: #fc4b4b;

    &:hover,
    &:focus {
        color: #fc4b4b;
    }
}

.text-green {
    color: #37bc9b;
}

.text-blue {
    color: #0068e1;
}

/* label */

.label {
    font-weight: normal;
    padding: 5px 10px;
}

.label-default {
    background: #d2d6de;
}

.label-primary {
    background: #0068e1;
}

.label-success {
    background: #37bc9b;
}

.label-danger {
    background: #fc4b4b;
}


/* form error */

.has-error {
    .help-block,
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label {
        color: #ff3366;
    }

    .form-control {
        border-color: #ff3366;
        box-shadow: none;

        &:focus {
            border-color: #ff3366;
            box-shadow: none;
        }
    }

    .input-group-addon {
        color: #ff3366;
        background-color: #f2dede;
        border-color: #ff3366;
    }

    .form-control-feedback {
        color: #ff3366;
    }
}

.help-block {
    margin-bottom: 0;
}

.checkbox {
    label {
        font-size: 15px;
        color: #333333;
        margin-bottom: 0 !important;
    }

    [type="checkbox"] {
        &:checked,
        &:not(:checked) {
            position: absolute;
            display: none;
        }

        &:checked + label,
        &:not(:checked) + label {
            font-family: "Roboto", sans-serif;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            display: inline-block;
            text-align: left;
        }

        &:checked + label:before,
        &:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 17px;
            height: 17px;
            border-radius: 3px;
            background: #e9e9e9;
            transition: 200ms ease-in-out;
        }

        &:checked + label:after,
        &:not(:checked) + label:after {
            content: "\f00c";
            font-family: FontAwesome;
            font-size: 13px;
            position: absolute;
            top: 1px;
            left: 2px;
            color: #ffffff;
            -webkit-text-stroke: 1px #0068e1;
            transition: 200ms ease-in-out;
        }

        &:checked + label:before {
            background: #0068e1;
        }

        &:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
        }

        &:checked + label:after {
            -webkit-text-stroke: 1px #0068e1;
            opacity: 1;
            transform: scale(1);
        }
    }
}

/* radio button */

.radio {
    text-align: left;

    label {
        color: #333333;
    }

    [type="radio"] {
        &:checked,
        &:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        &:checked + label,
        &:not(:checked) + label {
            font-family: "Roboto", sans-serif;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 22px;
            display: inline-block;
        }

        &:checked + label:before,
        &:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 19px;
            height: 19px;
            border: 1px solid #d2d6de;
            border-radius: 100%;
            background: #ffffff;
        }

        &:checked + label:after {
            content: "";
            width: 13px;
            height: 13px;
            background: #0068e1;
            position: absolute;
            top: 4px;
            left: 3px;
            border-radius: 100%;
            transition: 200ms ease-in-out;
        }

        &:not(:checked) + label:after {
            content: "";
            width: 14px;
            height: 14px;
            background: #0068e1;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            transition: 200ms ease-in-out;
            opacity: 0;
            transform: scale(0);
        }

        &:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }
    }

    + .radio {
        margin-top: 0;
    }
}

.checkbox + .checkbox {
    margin-top: 0;
}

/* select option */

.custom-select-white {
    appearance: none;
    background: #f9f9f9 url('../images/arrow-white.png') no-repeat right 8px center;
    background-size: 10px;
    line-height: normal !important;
    height: 40px;
    padding: 0 30px 0 10px;
    border-radius: 3px;
    border-color: #d9d9d9;
}

.custom-select-black {
    appearance: none;
    background: #ffffff url('../images/arrow-black.png') no-repeat right 8px center;
    background-size: 10px;
    line-height: normal !important;
    height: 40px;
    padding: 0 30px 0 10px;
    border-radius: 3px;
    border-color: #d9d9d9;
}

.custom-select-white:focus,
.custom-select-black:focus {
    outline: 0;
}
