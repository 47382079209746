.p-tb-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.p-tb-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.p-tb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.p-tb-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.p-t-0 {
	padding-top: 0;
}

.p-t-5 {
	padding-top: 5px;
}

.p-t-10 {
	padding-top: 10px;
}

.p-t-15 {
	padding-top: 15px;
}

.p-b-0 {
	padding-bottom: 0;
}

.p-b-5 {
	padding-bottom: 5px;
}

.p-b-10 {
	padding-bottom: 10px;
}

.p-b-15 {
	padding-bottom: 15px;
}

.p-l-0 {
	padding-left: 0;
}

.p-l-5 {
	padding-left: 5px;
}

.p-l-10 {
	padding-left: 10px;
}

.p-l-15 {
	padding-left: 15px;
}

.p-r-0 {
	padding-right: 0;
}

.p-r-5 {
	padding-right: 5px;
}

.p-r-10 {
	padding-right: 10px;
}

.p-r-15 {
	padding-right: 15px;
}

.m-tb-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.m-tb-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.m-tb-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.m-tb-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.m-t-0 {
	margin-top: 0;
}

.m-t-5 {
	margin-top: 5px;
}

.m-t-10 {
	margin-top: 10px;
}

.m-t-15 {
	margin-top: 15px;
}

.m-b-0 {
	margin-bottom: 0;
}

.m-b-5 {
	margin-bottom: 5px;
}

.m-b-10 {
	margin-bottom: 10px;
}

.m-b-15 {
	margin-bottom: 15px;
}

.m-l-0 {
	margin-left: 0;
}

.m-l-5 {
	margin-left: 5px;
}

.m-l-10 {
	margin-left: 10px;
}

.m-l-15 {
	margin-left: 15px;
}

.m-r-0 {
	margin-right: 0;
}

.m-r-5 {
	margin-right: 5px;
}

.m-r-10 {
	margin-right: 10px;
}

.m-r-15 {
	margin-right: 15px;
}

.no-border {
	border: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-shadow {
	box-shadow: none !important;
}

.cursor-auto {
	cursor: auto !important;
}

.text-left {
	text-align: left;
}
